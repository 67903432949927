// Color Detail Page

import React, { useEffect, memo, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Box, Stack } from 'grommet';
import { useStaticQuery, graphql } from 'gatsby';
import { get, find } from '../../lib/nodash';
import kebabCase from 'lodash.kebabcase';
import { useSelector, useDispatch } from 'react-redux';
import { useStoryblokState } from '../../lib/storyblok';

import HeroProductDetail from '../HeroProductDetail';

import ModuleSliderGallery from '../ModuleSliderGallery';
import ModalPaintCalculator from '../Calculator/ModalPaintCalculator';
import PaintBuyOptions from './PaintBuyOptions';
import ProductBreadcrumb from '../Product/ProductBreadcrumb';
import Buybar from '../Buybar';
import usePageBodyComponents from '../DynamicPage/usePageBodyComponents';
import usePaintCollectionData from './usePaintCollectionData';
import { track } from '../../lib/analytics';
import { PDP_GALLERY_INTERACTION } from '../../lib/analytics/segmentActions';

import { findSampleVariant } from '../../lib/product';
import { viewPlacementDetails } from '../../state/gallery/gallerySlice';
import { reset } from '../../state/calculator/calculatorSlice';
import { setDark } from '../../state/ui/uiSlice';

const PageProductPaint = ({
  product,
  paintCollection,
  relatedProducts,
  similarCollections,
  relatedCollections,
  reviews = [],
}) => {
  const { pdp, specs } = useStaticQuery(graphql`
    query PaintPDP {
      pdp: storyblokEntry(slug: { eq: "paint-pdp" }) {
        id
        name
        full_slug
        content
        field_title_string
        internalId
      }
      specs: allStoryblokEntry(
        filter: { full_slug: { glob: "paint/specs/*" } }
      ) {
        edges {
          node {
            name
            slug
            full_slug
            content
            internalId
          }
        }
      }
    }
  `);

  const productTypeSpecs = find(
    (x) =>
      product.productType.includes('Cabinet')
        ? x.slug.includes('cabinet-and-door')
        : x.slug.includes(kebabCase(product.productType)),
    specs.edges.map((x) => x.node)
  );

  const pdpTemplate = useStoryblokState(pdp);

  const dispatch = useDispatch();

  const {
    title,
    hexValue,
    isDark,
    description,
    products,
    variants,
    tags,
    shortDescription,
    collectionImage,
    heroImage,
    handle: paintColorHandle,
  } = usePaintCollectionData(paintCollection, product);

  const sampleVariant = useMemo(() => findSampleVariant(variants), [variants]);
  const borderSettings = { side: 'bottom', size: 'small', color: 'black' };
  const handleViewPlacement = useCallback(
    (action) => {
      const placement = find({ id: action.placementId }, action.placements);
      track(PDP_GALLERY_INTERACTION, {
        product: title,
        imageUrl: get('ugc.file.url', placement),
        ugcId: get('ugc.id', placement),
        attribution: get('ugc.attribution', placement),
      });
      dispatch(viewPlacementDetails(action));
    },
    [dispatch, viewPlacementDetails, PDP_GALLERY_INTERACTION, title]
  );

  const activePlacementId = useSelector(
    (state) => state.gallery.activePlacementId
  );

  const pageComponents = usePageBodyComponents(pdpTemplate, {
    collection: paintCollection,
    paintColor: paintCollection,
    product,
    relatedProducts,
    relatedCollections,
    similarCollections,
    specsContent: productTypeSpecs,
    reviews,
  });

  useEffect(() => {
    dispatch(setDark(isDark));

    return () => {
      dispatch(setDark(false));
      dispatch(reset());
    };
  }, [isDark]);

  return (
    <>
      <Stack anchor="bottom">
        <Box style={{ position: 'relative' }}>
          <Box
            className="breadcrumb-wrapper"
            style={{ position: 'absolute', zIndex: 10 }}
            pad="medium"
          >
            <ProductBreadcrumb
              product={{
                title: title,
                productType: get('productType', product),
                handle: get('handle', product),
                tags: tags,
              }}
              base={{
                title: 'Paint',
                slug: 'collections/paint/',
              }}
            />
          </Box>
          <HeroProductDetail
            variants={variants}
            title={title}
            backgroundColor={hexValue}
            description={description}
            shortDescription={shortDescription}
            border={borderSettings}
            dark={isDark}
            heroImage={heroImage}
            type={'Paint'}
            productType={get('productType', product)}
            pixelSwap={false}
          />
          {pageComponents}
          <ModuleSliderGallery
            identifier={paintColorHandle}
            border={borderSettings}
            mainProductTitle={title}
            mainProductDescription={description}
            activePlacementId={activePlacementId}
            onViewPlacement={handleViewPlacement}
          />
        </Box>
        <Buybar>
          <PaintBuyOptions
            sample={sampleVariant}
            products={products}
            paintCollection={paintCollection}
            dark={isDark}
            product={product}
            imageUrl={get('original_src', collectionImage)}
          />
        </Buybar>
      </Stack>
      <ModalPaintCalculator
        product={product}
        collection={paintCollection}
        productGroup={paintCollection}
      />
    </>
  );
};

PageProductPaint.propTypes = {
  product: PropTypes.object,
  collection: PropTypes.object,
  paintCollection: PropTypes.object,
  relatedProducts: PropTypes.array,
  relatedCollections: PropTypes.array,
  similarCollections: PropTypes.array,
  location: PropTypes.object,
  reviews: PropTypes.array,
};

export default memo(PageProductPaint);
